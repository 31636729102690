import React from "react";
import "./App.css";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Announcement from "@material-ui/icons/Announcement";
import ChatBubble from "@material-ui/icons/ChatBubble";
import SportsCricket from "@material-ui/icons/SportsCricket";
import Data from "./data";
import Button from "@material-ui/core/Button";

function App() {
  return (
    <div className="App">
      <div className="logo">Jistly</div>
      <p className="logo-text">Get the jist of it!</p>
      <VerticalTimeline>
        <h1 className="twenty-twenty">2020</h1>
        {Data.map((item, index) => (
          <>
            <VerticalTimelineElement
              key={item}
              className="vertical-timeline-element--work"
              date={item.date}
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              icon={
                item.icon === "ChatBubble" ? (
                  <ChatBubble />
                ) : item.icon === "SportsCricket" ? (
                  <SportsCricket />
                ) : (
                  <Announcement />
                )
              }
            >
              <h4 className="vertical-timeline-element-subtitle">
                {item.location}
              </h4>
              <p>{item.description}</p>
              <br />
              <Button
                variant="contained"
                color={item.ctaColour}
                href={item.link}
                style={{
                  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)"
                }}
                target="_blank"
              >
                {item.cta}
              </Button>
            </VerticalTimelineElement>
          </>
        ))}
        <h1 className="twenty-twenty-one">2021</h1>
      </VerticalTimeline>
    </div>
  );
}

export default App;
